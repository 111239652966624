import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'

import styles from './FormDialogStyles'

const FormDialog = ({
  form,
  handleClose,
  translate,
  classes: { buttonBigger, buttonReload },
  handleSubmit,
  children,
  title,
  text,
  invalid,
}) => {
  const titleId = `${form}-form-dialog-title`
  return (
    <Dialog open onClose={handleClose} aria-labelledby={titleId}>
      <form onSubmit={handleSubmit} name={form} autoComplete="off">
        <DialogTitle id={titleId}>{translate(title)}</DialogTitle>
        <DialogContent>
          {text && <DialogContentText>{translate(text)}</DialogContentText>}
          {children}
        </DialogContent>
        <DialogActions>
          <Button className={buttonReload} onClick={handleClose}>
            {translate('app.action.cancel')}
          </Button>
          <Button
            className={buttonBigger}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={invalid}
          >
            {translate('app.action.confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

FormDialog.defaultProps = {
  text: null,
}

FormDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    buttonBigger: PropTypes.string.isRequired,
    buttonReload: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
}

export default compose(withStyles(styles), i18n)(FormDialog)
