import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  withStyles,
  Paper,
  Button,
  MobileStepper,
  Grid,
} from '@material-ui/core'
import {
  Stars,
  Person,
  KeyboardArrowLeft,
  HourglassFull,
  RemoveCircle,
  YoutubeSearchedFor,
  Schedule,
  RotateRight,
  Payment,
  Done,
  Error,
} from '@material-ui/icons'
import i18n from 'providers/i18n/I18nProvider'
import {
  HISTORY,
  INCIDENTS,
  INFORMATIONS,
  INVOICE,
  PLANIFICATION,
  STARTING_JOB,
  CERFA,
  BFT,
  PENDING_MATCHING_STATUS,
  PENDING_PAYMENT_STATUS,
  PENDING_ASSIGNMENT_STATUS,
  PENDING_MANUAL_ASSIGNMENT_STATUS,
  PENDING_REALIZATION_STATUS,
  JOB_IN_PROGRESS_STATUS,
  PENDING_DATA_STATUS,
  JOB_DONE_STATUS,
  PENDING_CANCELATION_STATUS,
  JOB_CANCELED_STATUS,
  PICTURES,
  MESSAGING,
  AFTER_SALE_ALL_TYPES,
  DIAGNOSTIC,
  MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE,
} from 'constants/Jobs'
import { withRouter } from 'react-router-dom'
import styles from './JobDetailsStyles'
import JobPlanificationContainer from './JobPlanification/JobPlanificationContainer'
import JobMessagingContainer from './JobMessaging/JobMessagingContainer'
import JobPanel from './Panels/JobPanel'
import JobInProgressUtilitiesContainer from './JobInProgressUtilities/JobInProgressUtilitiesContainer'
import JobHistoryContainer from './JobHistory/JobHistoryContainer'
import JobDetailsIncidentsContainer from './JobDetailsIncidents/JobDetailsIncidentsContainer'
import CerfaContainer from './JobDetailsWorkflow/Cerfa/CerfaContainer'
import ReceiptContainer from './JobDetailsWorkflow/Receipt/ReceiptContainer'
import InvoicesContainer from './JobDetailsInvoices/JobDetailsInvoicesContainer'
import PaymentStepContainer from './JobDetailsWorkflow/PaymentStep/PaymentStepContainer'
import JobDetailsNavigationContainer from './JobDetailsNavigationContainer'
import JobDetailsWorksiteJobsNavigationContainer from './JobDetailsWorksiteJobsNavigationContainer'
import JobDetailsClientOrder from './JobDetailsClientOrder'
import JobDetailsOrderPicturesContainer from './JobDetailsOrder/JobDetailsOrderPictures/JobDetailsOrderPicturesContainer'
import JobDiagnosticContainer from './JobDiagnostic/JobDiagnosticContainer'

const JobIcon = ({ status }) => {
  switch (status) {
    case PENDING_DATA_STATUS:
      return <HourglassFull />
    case PENDING_MATCHING_STATUS:
      return <RemoveCircle />
    case PENDING_ASSIGNMENT_STATUS:
      return <YoutubeSearchedFor />
    case PENDING_MANUAL_ASSIGNMENT_STATUS:
      return <Person />
    case PENDING_REALIZATION_STATUS:
      return <Schedule />
    case JOB_IN_PROGRESS_STATUS:
      return <RotateRight />
    case PENDING_PAYMENT_STATUS:
      return <Payment />
    case JOB_DONE_STATUS:
      return <Done />
    case PENDING_CANCELATION_STATUS:
    case JOB_CANCELED_STATUS:
      return <Error />
    default:
      return <Stars />
  }
}

JobIcon.propTypes = {
  status: PropTypes.string.isRequired,
}

const JobDetails = ({
  classes,
  status,
  jobId,
  translate,
  history: { goBack },
  showJobDetailsTab,
  isCerfaPackage,
  jobPictures,
  openedIncidentsCount,
  partnerId,
  jobType,
  hasDiagnostic,
}) => {
  const [expandedItems, setExpandedItems] = useState({
    [CERFA]: true,
    [BFT]: true,
    [HISTORY]: true,
    [INCIDENTS]: true,
    [INFORMATIONS]: true,
    [INVOICE]: true,
    [PLANIFICATION]: true,
    [MESSAGING]: true,
    [PICTURES]: true,
    [STARTING_JOB]: true,
    [DIAGNOSTIC]: true,
  })

  const handleChange = panel => (_, expanded) => {
    setExpandedItems({
      ...expandedItems,
      [panel]: expanded,
    })
  }

  const isExpanded = panel => expandedItems[panel]
  const isShownTab = id => showJobDetailsTab.includes(id)

  return (
    <Paper className={classes.JobsDetails}>
      <JobDetailsWorksiteJobsNavigationContainer />
      <JobDetailsNavigationContainer
        openedIncidentsCount={openedIncidentsCount}
      />
      <MobileStepper
        position="static"
        steps={0}
        className={classes.root}
        backButton={
          <Button size="small" onClick={goBack}>
            <KeyboardArrowLeft />
            {translate('job.back')}
          </Button>
        }
      />
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {/* CUST & ORDER INFOS */}
        <Grid item xs={6}>
          <JobPanel
            id={INFORMATIONS}
            expanded={isExpanded(INFORMATIONS)}
            handleChange={handleChange(INFORMATIONS)}
            title={translate('resources.jobs.subtitles.informations')}
          >
            <JobDetailsClientOrder partnerId={partnerId} jobId={jobId} />
          </JobPanel>
        </Grid>
        {/* PLANIFICATION */}
        <Grid container xs={6}>
          <Grid item xs={12}>
            <JobPanel
              id={PLANIFICATION}
              expanded={isExpanded(PLANIFICATION)}
              handleChange={handleChange(PLANIFICATION)}
              title={translate('resources.jobs.subtitles.planning')}
            >
              <JobPlanificationContainer
                jobId={jobId}
                openedIncidentsCount={openedIncidentsCount}
              />
            </JobPanel>
          </Grid>
          {/* STARTING JOB */}
          {isShownTab(STARTING_JOB) &&
            jobType !== MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE && (
              <Grid item xs={12}>
                <JobPanel
                  id={STARTING_JOB}
                  expanded={isExpanded(STARTING_JOB)}
                  handleChange={handleChange(STARTING_JOB)}
                  title={translate('resources.jobs.subtitles.startingJob')}
                >
                  <JobInProgressUtilitiesContainer jobId={jobId} />
                </JobPanel>
              </Grid>
            )}
          {/* Diagnostic */}
          {isShownTab(DIAGNOSTIC) &&
            hasDiagnostic &&
            jobType === MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE && (
              <Grid item xs={12}>
                <JobPanel
                  id={DIAGNOSTIC}
                  expanded={isExpanded(DIAGNOSTIC)}
                  handleChange={handleChange(DIAGNOSTIC)}
                  title={translate('resources.jobs.subtitles.diagnostic')}
                >
                  <JobDiagnosticContainer />
                </JobPanel>
              </Grid>
            )}
          {/* BFT */}
          {isShownTab(BFT) && (
            <Grid item xs={12}>
              <JobPanel
                id={BFT}
                expanded={isExpanded(BFT)}
                handleChange={handleChange(BFT)}
                title={translate('resources.jobs.subtitles.bft')}
              >
                <ReceiptContainer jobStatus={status} jobIri={jobId} />
              </JobPanel>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* PICTURES */}
      {jobPictures.length > 0 && (
        <JobPanel
          id={PICTURES}
          expanded={isExpanded(PICTURES)}
          handleChange={handleChange(PICTURES)}
          title={translate('resources.jobs.subtitles.pictures')}
        >
          <JobDetailsOrderPicturesContainer jobId={jobId} />
        </JobPanel>
      )}
      {/* MESSAGING */}
      <JobPanel
        id={MESSAGING}
        expanded={isExpanded(MESSAGING)}
        handleChange={handleChange(MESSAGING)}
        title={translate('resources.jobs.subtitles.messaging')}
      >
        <JobMessagingContainer jobId={jobId} />
      </JobPanel>
      {/* CERFA */}
      {isShownTab(CERFA) && isCerfaPackage && (
        <JobPanel
          id={CERFA}
          expanded={isExpanded(CERFA)}
          handleChange={handleChange(CERFA)}
          title={translate('resources.jobs.subtitles.cerfa')}
        >
          <CerfaContainer jobIri={jobId} />
        </JobPanel>
      )}
      {/* INVOICE */}
      {isShownTab(INVOICE) && (
        <JobPanel
          id={INVOICE}
          expanded={isExpanded(INVOICE)}
          handleChange={handleChange(INVOICE)}
          title={translate('resources.jobs.subtitles.invoices')}
        >
          <InvoicesContainer />
          <PaymentStepContainer jobStatus={status} jobIri={jobId} />
        </JobPanel>
      )}
      {/* EVENTS / INCIDENTS */}
      {!AFTER_SALE_ALL_TYPES.includes(jobType) && (
        <JobPanel
          id={INCIDENTS}
          expanded={isExpanded(INCIDENTS)}
          handleChange={handleChange(INCIDENTS)}
          title={translate('resources.jobs.subtitles.incidents')}
        >
          <JobDetailsIncidentsContainer jobId={jobId} />
        </JobPanel>
      )}
      {/* JOB HISTORY */}
      <JobPanel
        id={HISTORY}
        expanded={isExpanded(HISTORY)}
        handleChange={handleChange(HISTORY)}
        title={translate('resources.jobs.subtitles.history')}
      >
        <JobHistoryContainer jobId={jobId} />
      </JobPanel>
    </Paper>
  )
}

JobDetails.propTypes = {
  classes: PropTypes.shape({
    JobsDetails: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    ExpansionPanelBloc: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    workflow: PropTypes.string.isRequired,
    expansionPanelSummary: PropTypes.string.isRequired,
    expansionPanelDetails: PropTypes.string.isRequired,
    blockStatus: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  status: PropTypes.string,
  jobId: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  showJobDetailsTab: PropTypes.arrayOf(PropTypes.string).isRequired,
  isCerfaPackage: PropTypes.bool.isRequired,
  jobPictures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  openedIncidentsCount: PropTypes.number.isRequired,
  partnerId: PropTypes.string,
  jobType: PropTypes.string,
  hasDiagnostic: PropTypes.bool.isRequired,
}

JobDetails.defaultProps = {
  status: undefined,
  jobId: undefined,
  history: {
    goBack: () => {},
  },
  jobPictures: [],
  partnerId: undefined,
  jobType: undefined,
}

export default compose(i18n, withRouter, memo, withStyles(styles))(JobDetails)
