import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import { getJobDiagnostic, toDoneTransition } from 'store/jobs/jobActions'
import {
  toCancelTransitionIsLoadingSelector,
  jobIdSelector,
  statusOrSubstatusSelector,
  diagnosticSelector,
  receiptSignatureSelector,
  receiptSignatureStatusSelector,
  isInProgressOrFinishedReceiptSelector,
  currentJobTypeSelector,
} from 'store/jobs/jobSelectors'
import { showStatusJobDetailsSelector } from 'store/Application/ApplicationSelectors'
import PropTypes from 'prop-types'
import ToDoneLink from './ToDoneLink'

const ToDoneLinkContainer = ({
  jobIri,
  status,
  showStatus,
  isLoading,
  updateJobStatusAction,
  jobDiagnostic,
  diagInfos,
  receiptSignature,
  receiptSignatureStatus,
  isInProgressOrFinishedReceipt,
  jobType,
}) =>
  jobIri &&
  status &&
  showStatus && (
    <ToDoneLink
      jobIri={jobIri}
      status={status}
      showStatus={showStatus}
      isLoading={isLoading}
      updateJobStatusAction={updateJobStatusAction}
      jobDiagnostic={jobDiagnostic}
      diagInfos={diagInfos}
      receiptSignature={receiptSignature}
      receiptSignatureStatus={receiptSignatureStatus}
      isInProgressOrFinishedReceipt={isInProgressOrFinishedReceipt}
      jobType={jobType}
    />
  )

ToDoneLinkContainer.propTypes = {
  jobIri: PropTypes.string,
  status: PropTypes.string,
  showStatus: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  updateJobStatusAction: PropTypes.func.isRequired,
  jobDiagnostic: PropTypes.func.isRequired,
  diagInfos: PropTypes.shape({}),
  receiptSignature: PropTypes.shape({}),
  receiptSignatureStatus: PropTypes.string,
  isInProgressOrFinishedReceipt: PropTypes.bool.isRequired,
  jobType: PropTypes.string.isRequired,
}

ToDoneLinkContainer.defaultProps = {
  jobIri: false,
  status: false,
  isLoading: false,
  diagInfos: {},
  receiptSignature: undefined,
  receiptSignatureStatus: undefined,
}

const mapStateToProps = state => ({
  jobIri: jobIdSelector(state),
  status: statusOrSubstatusSelector(state),
  showStatus: showStatusJobDetailsSelector(state),
  isLoading: toCancelTransitionIsLoadingSelector(state),
  diagInfos: diagnosticSelector(state),
  receiptSignature: receiptSignatureSelector(state),
  receiptSignatureStatus: receiptSignatureStatusSelector(state),
  isInProgressOrFinishedReceipt: isInProgressOrFinishedReceiptSelector(state),
  jobType: currentJobTypeSelector(state),
})

const mapDispatchToProps = dispatch => ({
  updateJobStatusAction: jobIri =>
    dispatch(toDoneTransition.request({ jobIri })),
  jobDiagnostic: jobIri => dispatch(getJobDiagnostic.request({ jobIri })),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ToDoneLinkContainer)
