export const PERM_FIRM_READ_LEGAL = 'craftman:consult' // @todo not the definitive permission, should be firm:read:legal
export const PERM_FIRM_UPDATE_LEGAL = 'craftman:edit:legal' // @todo not the definitive permission, should be firm:update:legal
export const PERM_JOB_EXPORT = 'job:export'
export const PERM_INVOICE_EXPORT = 'invoice:export'
export const PERM_JOB_EDIT_ADDRESS = 'job:edit:address'
export const PERM_FIRM_UPDATE_IBAN = 'craftman:edit:iban'
export const PERM_JOB_EDIT_DIAGNOSTIC = 'job:edit:diagnostic'

export const hasPerm = (token, perm) => token?.permissions?.includes(perm)
export const hasJobEditAddressPerm = token =>
  hasPerm(token, PERM_JOB_EDIT_ADDRESS)
