import { isAfter, endOfYesterday, format, subDays } from 'date-fns'
import { isValid as isValidIban } from 'iban'
import { isValid as isValidSwiftCode } from 'bic'
import { checkVAT, france } from 'jsvat'
import { REGEXP_DEPARTMENT } from 'constants/regexp'
import { DATE_FORMAT } from 'constants/date'
import translate from 'providers/i18n/translateService'

export const email = (value, allValues, props) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? props.translate('app.errors.email_format')
    : undefined

export const required = (value, allValues, props) =>
  !value ? props.translate('app.errors.required') : undefined

export const requiredArray = (value, allValues, props) =>
  !value || value.length === 0
    ? props.translate('app.errors.required')
    : undefined

export const validatePhone = (value, allValues, props) =>
  value && !/^(0|\+33)[1-9][0-9]{8}$/gm.test(value)
    ? props.translate('resources.firms.validation.format_phone')
    : undefined

export const requiredNumber = value =>
  value || typeof value === 'number' ? undefined : 'Required'

export const dateAfterToday = value => {
  if (!value) {
    return undefined
  }

  return (
    !isAfter(new Date(value), endOfYesterday()) &&
    translate('resources.firms.fields.certificate.expirationDateBeforeError')
  )
}

export const isIban = (value, allValues, props) =>
  value && !isValidIban(value)
    ? props.translate('resources.firms.validation.format_iban')
    : undefined

export const isSwiftCode = (value, allValues, props) =>
  value && !isValidSwiftCode(value)
    ? props.translate('resources.firms.validation.format_swift_code')
    : undefined

export const isVat = (value, allValues, props) =>
  value && !checkVAT(value, [france]).isValid
    ? props.translate('resources.firms.validation.format_vat')
    : undefined

export const department = (value, allValues, props) =>
  value && !REGEXP_DEPARTMENT.test(value)
    ? props.translate('resources.firms.validation.format_department')
    : undefined

export const maxLength = max => (value, allValues, props) =>
  value && value.length > max
    ? props.translate('app.errors.max_length', { max })
    : undefined

export const isNumber = (value, allValues, props) =>
  value && Number.isNaN(Number(value))
    ? props.translate('app.errors.is_number')
    : undefined

export const minValue = min => (value, allValues, props) =>
  value && value < min
    ? props.translate('app.errors.min_value', { min })
    : undefined

export const maxValue = max => (value, allValues, props) =>
  value && value > max
    ? props.translate('app.errors.max_value', { max })
    : undefined

export const isInteger = (value, allValues, props) =>
  value && !Number.isInteger(Number(value))
    ? props.translate('app.errors.is_integer')
    : undefined

/**
 * @param {Date} minDate
 *
 * @returns {function(string|undefined, *, {translate: function}): (string|undefined)}
 */
export const createDateGteMinDate = minDate => (
  dateValueStr,
  _,
  { translate },
) => {
  if (!dateValueStr) {
    return undefined
  }

  const dayBeforeMinDate = subDays(new Date(minDate), 1)

  return isAfter(new Date(dateValueStr), dayBeforeMinDate)
    ? undefined
    : translate('app.errors.date_gte', { date: format(minDate, DATE_FORMAT) })
}
