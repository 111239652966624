import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  getFormSyncErrors,
  reduxForm,
  reset,
  submit,
  getFormValues,
} from 'redux-form'

import i18n from 'providers/i18n/I18nProvider'
import { diagnosticSelector, jobIdSelector } from 'store/jobs/jobSelectors'
import { selectAccessTokenDecoded } from 'store/Application/ApplicationSelectors'
import { EDIT_DIAG_FORM } from 'constants/forms'
import { updateDiag } from 'store/jobs/jobActions'
import {
  maxValue,
  minValue,
  requiredNumber,
  required,
} from 'helpers/form/validationHelpers'
import { applyValidationRules } from 'helpers/utils/validate'
import { FIELD_DIAG_AMOUNT } from 'constants/Jobs'

import JobDiagnostic from './JobDiagnostic'

const JobDiagnosticContainer = ({ diagInfos }) => (
  <JobDiagnostic diagInfos={diagInfos} />
)

JobDiagnosticContainer.propTypes = {
  diagInfos: PropTypes.shape({}).isRequired,
}

const mapStateToProps = state => ({
  diagInfos: diagnosticSelector(state),
  token: selectAccessTokenDecoded(state),
  formErrors: getFormSyncErrors(EDIT_DIAG_FORM)(state),
  formValues: getFormValues(EDIT_DIAG_FORM)(state),
  jobIri: jobIdSelector(state),
})

const mapDispatchToProps = dispatch => ({
  submitFormDispatch: () => dispatch(submit(EDIT_DIAG_FORM)),
})

export const validate = (values, props) => {
  const fieldsWithRules = {
    [FIELD_DIAG_AMOUNT]: [minValue(1), maxValue(700), requiredNumber, required],
  }

  return applyValidationRules(fieldsWithRules, values, props)
}

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: EDIT_DIAG_FORM,
    enableReinitialize: false,
    validate,
    fields: [FIELD_DIAG_AMOUNT],
    onSubmit: ({ additionalFeesAmount }, dispatch, { jobIri, diagInfos }) => {
      dispatch(
        updateDiag.request({
          data: {
            additionalFeesAmount: parseFloat(additionalFeesAmount),
          },
          jobIri,
          diagInfos,
        }),
      )
    },
    onSubmitSuccess: (result, dispatch) => {
      dispatch(reset(EDIT_DIAG_FORM))
    },
  }),
)(JobDiagnostic)
