/* eslint no-return-assign: [2, "except-parens"] */
import { all, put, select, takeLatest, delay, call } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { saveAs } from 'file-saver'
import translate from 'providers/i18n/translateService'
import engineClient from 'services/httpClient/engineClient'
import n8nClient from 'services/httpClient/n8nClient'
import syliusClient from 'services/httpClient/syliusClient'
import { handleRequest } from 'helpers/store/sagasHelpers'
import { INFO, WARNING, ERROR, SUCCESS } from 'constants/variant'
import { dateFormatter } from 'helpers/date'
import {
  PRO_NOT_NOTIFIED_STATUS,
  HO_STATUS,
  SEARCH,
  JOB_EVENT_HISTORY_PAGE,
  JOB_EVENT_HISTORY_PER_PAGE,
  RECEIPT_SIGNATURE_STATE_FINISHED,
  RECEIPT_SIGNATURE_STATE_MANUAL,
  JOBS_STATUS,
} from 'constants/Jobs'
import {
  formatIriForTransition,
  getJobIdFromIri,
  getJobIriFromId,
} from 'helpers/utils/job/job'
import {
  showNotification,
  loadingData,
} from 'store/Application/ApplicationActions'
import {
  SEARCH_PROS_MANUAL_MATCHING,
  FILTER_JOBS_LIST_FORM,
  NEW_TIME_SLOT_DATE_FORM,
} from 'constants/forms'
import { POST_INCIDENT_REQ } from 'store/incidents/incidentActions'
import { INVOICE_PAY_TRANSITION_REQ } from 'store/invoices/transitions/invoiceTransitionActions'
import { phoneNumberNormalizationForSearch } from 'helpers/search/searchJobHelpers'
import {
  removeEmptyAttribute,
  removeUnchangedAttribute,
} from 'helpers/utils/common'
import { getSingleWorksite } from 'store/worksites/worksiteActions'
import { currentWorksiteSelector } from 'store/worksites/worksiteSelectors'
import { GEOZONES_DEPARTMENTS } from 'constants/dashboard'
import { partnerByChannelSelector } from 'store/partners/partnersSelectors'
import { JOB_PATH_MANUAL_MATCHING_PREFIX } from 'constants/routes'
import { push } from 'react-router-redux'
import {
  getJobsList,
  exportJobsList,
  getSingleJob,
  refreshJob,
  getMatchingPros,
  getMatchingProsListAlgolia,
  getMatchingProsListAlgoliaRanking,
  getCountJobsByStatuses,
  GET_JOBS_LIST,
  EXPORT_JOBS_LIST,
  GET_SINGLE_JOB,
  REFRESH_JOB,
  RESET_PROS_SEARCH,
  GET_MATCHING_PROS,
  GET_MATCHING_PROS_LIST,
  GET_MATCHING_PROS_LIST_RANKING,
  GET_COUNT_JOBS_BY_STATUSES,
  jobAddComment,
  JOB_ADD_COMMENT_REQ,
  getJobEventsForJob,
  GET_JOB_EVENTS_FOR_JOB_REQ,
  TO_PENDING_PAYMENT_TRANSITION_REQ,
  UPDATE_JOB_REQ,
  updateJob,
  RELIABLE_PRO_LIST,
  reliableProList,
  JOB_RESCHEDULING_TRANSITION_REQ,
  JOB_DECLINE_TRANSITION_REQ,
  TO_PENDING_CANCELATION_TRANSITION_REQ,
  GENERATE_SUPPORTING_DOCUMENTS_REQ,
  GENERATE_DISPUTE_DOCUMENTS_REQ,
  GET_RECEIPT_JOB,
  getReceiptJob,
  GET_CERFA_JOB,
  getCerfaJob,
  GET_RECEIPT_SIGNATURE,
  getReceiptSignature,
  GET_CERFA_SIGNATURE,
  getCerfaSignature,
  UPDATE_JOB_HO_DATE_REQ,
  updateJobHoDate,
  RESET_JOBS_SEARCH,
  resetJobsList,
  resetJobsDashboardParams,
  UNBLOCK_JOB_RECEIPT,
  GET_MATCHINGS,
  getMatchings,
  generateSupportingDocuments,
  generateDisputeDocuments,
  POST_CLIENT_DATA_REQ,
  postClientData,
  TO_CANCEL_TRANSITION_REQ,
  generateReinterventionAfterSale,
  GENERATE_REINTERVENTION_AFTERSALE_REQ,
  TO_DONE_TRANSITION_REQ,
  GET_JOB_DIAGNOSTIC,
  getJobDiagnostic,
  UPDATE_DIAG_REQ,
  updateDiag,
} from './jobActions'
import {
  productCodesSelector,
  matchingProsSelector,
  currentJobHistoryParametersSelector,
  jobIdSelector,
  initialState,
  lastMatchingIdSelector,
} from './jobSelectors'

/**
 * @param  {Object} searchParams
 * @return {string}
 */
const buildJobParams = searchParams => {
  let url = {}

  if (searchParams.jobStatus !== SEARCH) {
    const status = JOBS_STATUS.filter(
      element => element.key === searchParams.jobStatus,
    ).shift()

    url = {
      ...url,
      ...status.filters,
    }
  }

  if (searchParams.rowsPerPage) {
    url = {
      ...url,
      page: searchParams.page + 1,
      perPage: searchParams.rowsPerPage,
    }
  }

  if (searchParams.pagination === false) {
    url = {
      ...url,
      pagination: false,
    }
  }

  if (searchParams.orderBy && searchParams.sort) {
    url = {
      ...url,
      [`order[${searchParams.orderBy}]`]: searchParams.sort,
    }
  }

  if (searchParams.orderNumber) {
    url = {
      ...url,
      [`worksite.order.orderNumber`]: searchParams.orderNumber,
    }
  }

  if (searchParams.job) {
    url = {
      ...url,
      [`job`]: searchParams.job,
    }
  }

  if (searchParams.pro) {
    url = {
      ...url,
      [`acceptedPro`]: searchParams.pro,
    }
  }

  if (searchParams.lastName) {
    url = {
      ...url,
      [`worksite.order.customer.lastName`]: searchParams.lastName,
    }
  }

  if (searchParams.phoneNumber) {
    url = {
      ...url,
      [`worksite.order.customer.phoneNumber`]: searchParams.phoneNumber,
    }
  }

  if (searchParams.storeCode) {
    url = {
      ...url,
      [`worksite.order.store.code`]: searchParams.storeCode,
    }
  }

  if (searchParams.packageType) {
    url = {
      ...url,
      [`products.code`]: searchParams.packageType,
    }
  }

  if (searchParams.incidentStatus) {
    url = {
      ...url,
      [`incidents.status`]: searchParams.incidentStatus,
    }
  }

  if (searchParams.incidentWaitingFor) {
    url = {
      ...url,
      [`incidents.waitingFor`]: searchParams.incidentWaitingFor,
    }
  }

  if (searchParams.postCodes) {
    url = {
      ...url,
      [`postcode`]: searchParams.postCodes.length
        ? searchParams.postCodes
        : null,
    }
  }

  if (searchParams.postCode) {
    url = {
      ...url,
      [`worksite.address.postcode`]: searchParams.postCode,
    }
  }

  if (searchParams.minDateHo) {
    const nextDay = new Date(searchParams.minDateHo)
    nextDay.setDate(nextDay.getDate() + 1)
    url = {
      ...url,
      [`minDateHo[after]`]: searchParams.minDateHo,
      [`minDateHo[strictly_before]`]: dateFormatter(nextDay, 'yyyy-MM-dd'),
    }
  }

  if (searchParams.minDatePro) {
    const nextDay = new Date(searchParams.minDatePro)
    nextDay.setDate(nextDay.getDate() + 1)
    url = {
      ...url,
      [`minDatePro[after]`]: searchParams.minDatePro,
      [`minDatePro[strictly_before]`]: dateFormatter(nextDay, 'yyyy-MM-dd'),
    }
  }

  if (searchParams.reserveType) {
    url = {
      ...url,
      reserveType: searchParams.reserveType.map(type => type.value),
    }
  }

  if (searchParams.withReceiptWithReserves) {
    url = {
      ...url,
      withReceiptWithReserves: searchParams.withReceiptWithReserves,
    }
  }

  if (searchParams.partner) {
    url = {
      ...url,
      [`worksite.partner.id`]: searchParams.partner,
    }
  }

  return url
}

function* engineAPI(requestActions, promise, actionParams = {}, key = '') {
  try {
    return yield* handleRequest({
      requestActions,
      promise,
      actionParams,
    })
  } catch (e) {
    yield put(
      showNotification({
        payload: {
          messageType: ERROR,
          message: key ? translate(`job.${key}.req_failure`) : null,
          isNotified: !!key,
        },
      }),
    )
  }
  return undefined
}

export function* handleGetJobList(searchParams) {
  if (!searchParams) {
    return
  }

  const searchParamsWithSort = !searchParams.orderBy
    ? {
        ...searchParams,
        orderBy: HO_STATUS.includes(searchParams.jobStatus)
          ? 'minDateHo'
          : 'minDatePro',
        sort: 'ASC',
      }
    : searchParams

  if (searchParamsWithSort?.partner) {
    const partner = yield select(partnerByChannelSelector)
    searchParamsWithSort.partner = partner?.[searchParamsWithSort.partner].id
  }

  const jobParams = {
    params: buildJobParams(
      phoneNumberNormalizationForSearch(searchParamsWithSort),
    ),
  }

  const p = call(engineClient.get, 'jobs', jobParams)
  const options = { triggerModalLoader: true }

  yield* engineAPI(getJobsList, p, options, 'job_list')
}

export function* handleExportJobList(searchParams) {
  if (!searchParams) {
    return
  }

  const searchParamsWithSort = !searchParams.orderBy
    ? {
        ...searchParams,
        orderBy: HO_STATUS.includes(searchParams.jobStatus)
          ? 'minDateHo'
          : 'minDatePro',
        sort: 'ASC',
      }
    : searchParams

  const p = call(engineClient.get, 'jobs.csv', {
    responseType: 'blob',
    headers: {
      Accept: 'text/csv',
    },
    params: buildJobParams({
      ...searchParamsWithSort,
      pagination: false,
      rowsPerPage: null,
      page: null,
    }),
  })
  const options = { triggerModalLoader: true }
  const response = yield* engineAPI(exportJobsList, p, options, 'job_list')

  // invoke 'Save As' dialog
  saveAs(response.data, 'Orders_search_export.csv')
}

export function* handleGetSingleJob(searchParams) {
  if (
    !searchParams ||
    (!searchParams.jobId &&
      !searchParams.payload['@id'] &&
      !searchParams.payload.jobId)
  ) {
    return
  }
  const jobId = formatIriForTransition(
    searchParams.jobId ||
      (searchParams.payload.jobId
        ? getJobIriFromId(searchParams.payload.jobId)
        : null) ||
      searchParams.payload['@id'],
  )
  const options = { triggerModalLoader: true }
  const p = call(engineClient.get, jobId)
  yield* engineAPI(getSingleJob, p, options, 'job_show')
}

export function* handleRefreshJob({ jobId }) {
  const options = { triggerModalLoader: false }
  const p = call(engineClient.get, formatIriForTransition(jobId))
  yield* engineAPI(refreshJob, p, options, 'job_show')
}

export function* handleResetProsSearch(searchParams) {
  // reset redux form
  yield put(reset(SEARCH_PROS_MANUAL_MATCHING))
  yield handleGetMatchingProsAlgolia(searchParams)
  yield handleGetMatchingProsAlgoliaRanking(searchParams)
  yield handleGetMatchingPros(searchParams)
}

export function* handleResetJobsSearch() {
  yield put(resetJobsDashboardParams())
  yield put(resetJobsList())
  yield put(reset(FILTER_JOBS_LIST_FORM))
}

export function* handleGetMatchingProsAlgolia(searchParams) {
  if (!searchParams || !searchParams.jobId) {
    return
  }
  const { proLegacyId, proLastName } = searchParams
  const jobId = formatIriForTransition(searchParams.jobId)
  const jobParams = {
    params: { 'pro.legacyId': proLegacyId, 'pro.lastName': proLastName },
  }
  const options = { triggerModalLoader: true }
  const p = call(engineClient.get, `${jobId}/algolia_matching_pros`, jobParams)
  yield* engineAPI(getMatchingProsListAlgolia, p, options, 'job_show')
}

export function* handleGetMatchingProsAlgoliaRanking(searchParams) {
  if (!searchParams || !searchParams.jobId) {
    return
  }

  const jobId = formatIriForTransition(searchParams.jobId)
  const jobParams = {
    params: {
      simulation: true,
    },
  }
  const options = { triggerModalLoader: true }
  const p = call(engineClient.get, `${jobId}/algolia_matching_pros`, jobParams)
  yield* engineAPI(getMatchingProsListAlgoliaRanking, p, options, 'job_show')
}

export function* handleGetMatchingPros(searchParams) {
  const lastMatchingId = yield select(lastMatchingIdSelector)
  if (!lastMatchingId) {
    return
  }

  const { page, rowsPerPage, proLegacyId, proLastName } = searchParams
  const jobParams = {
    params: {
      page,
      perPage: rowsPerPage,
      'order[index]': 'ASC',
      'pro.legacyId': proLegacyId,
      'pro.lastName': proLastName,
      'pro.packageStatus': ['validated', 'pending_test_job'],
    },
  }
  const options = { triggerModalLoader: true }
  const p = call(
    engineClient.get,
    `matchings/${lastMatchingId}/matching_pros`,
    jobParams,
  )
  yield* engineAPI(getMatchingPros, p, options, 'job_show')
}

export function* handleGetCountJobsByStatuses({ geozone }) {
  let queryParams = {}
  if (geozone !== 'all') {
    queryParams = {
      params: {
        geozones: GEOZONES_DEPARTMENTS[geozone],
      },
    }
  }

  const p = call(engineClient.get, 'jobs-count/statuses', queryParams)
  yield* engineAPI(getCountJobsByStatuses, p)
}

function* handleAddJobCommentRequest({ data, searchParams }) {
  try {
    yield* handleRequest({
      requestActions: jobAddComment,
      promise: call(engineClient.post, 'job_events', data),
      actionParams: {
        triggerModalLoader: true,
      },
    })

    yield put(reset('commentForm'))
    yield put(getJobEventsForJob.request({ searchParams }))

    yield put(
      showNotification({
        payload: {
          message: translate('job.job_add_comment.req_success'),
          messageType: INFO,
        },
      }),
    )
  } catch (e) {
    console.error(e)
    yield put(
      showNotification({
        payload: {
          message: translate('job.job_add_comment.req_failure'),
          messageType: WARNING,
        },
      }),
    )
  }
}

function* handleGetJobEventsForJobRequest({ searchParams }) {
  const jobParams = { params: { ...searchParams, page: searchParams.page + 1 } }
  const p = call(engineClient.get, 'job_events', jobParams)
  yield* engineAPI(getJobEventsForJob, p, jobParams, 'job_events_for_job')
}

function* handleRefreshJobEvents() {
  const jobIri = yield select(jobIdSelector)
  const searchParams = {
    perPage: JOB_EVENT_HISTORY_PER_PAGE,
    page: JOB_EVENT_HISTORY_PAGE,
    job: jobIri,
  }
  yield put(getJobEventsForJob.request({ searchParams }))
}

function* handleUpdateJobReq({ jobData, documentName }) {
  try {
    yield* handleRequest({
      requestActions: updateJob,
      promise: call(
        engineClient.put,
        formatIriForTransition(jobData['@id']),
        jobData,
      ),
      actionParams: {
        triggerModalLoader: true,
      },
    })
    const jobIri = yield select(jobIdSelector)
    const searchParams = {
      ...initialState.searchParams,
      job: jobIri,
    }

    yield put(getJobEventsForJob.request({ searchParams }))

    yield put(
      showNotification({
        payload: {
          message: translate('job.validate_bills.update_job.success', {
            documentName,
          }),
          messageType: SUCCESS,
        },
      }),
    )
  } catch (e) {
    console.error(e)
    yield put(
      showNotification({
        payload: {
          message: translate('job.validate_bills.update_job.failure', {
            documentName,
          }),
          messageType: WARNING,
        },
      }),
    )
  }
}

const formatDatesHo = values => {
  let dates = []
  values.members.forEach(element => {
    const date = new Date(element.timeslotDate)
    const timeSlotRange = element.timeslot.split('-')
    dates = [
      ...dates,
      {
        startTime: `${element.timeslotDate}T${timeSlotRange[0]}
          +0${(date.getTimezoneOffset() / 60) * -1}:00`,
        stopTime: `${element.timeslotDate}T${timeSlotRange[1]}
          +0${(date.getTimezoneOffset() / 60) * -1}:00`,
      },
    ]
  })

  return dates
}

function* handleUpdateJobHoDateReq({ values, jobId }) {
  const { addButtonClicked } = values
  const newTimeslots = formatDatesHo(values)

  // PATCH method used for add timeslots
  // PUT method used for replace timeslots
  try {
    yield* handleRequest({
      requestActions: updateJobHoDate,
      promise: addButtonClicked
        ? call(
            engineClient.patch,
            `jobs/${getJobIdFromIri(jobId)}/timeslots`,
            newTimeslots,
          )
        : call(
            engineClient.put,
            `jobs/${getJobIdFromIri(jobId)}/timeslots`,
            newTimeslots,
          ),
      actionParams: {
        triggerModalLoader: true,
      },
    })
    yield put(loadingData(true))
    yield put(reset(NEW_TIME_SLOT_DATE_FORM))
    const searchParams = yield select(currentJobHistoryParametersSelector)
    yield delay(900)
    yield put(refreshJob.request({ jobId }))
    yield put(
      getJobEventsForJob.request({
        searchParams: { ...searchParams, job: jobId },
      }),
    )
    yield put(
      showNotification({
        payload: {
          message: translate('job.replace_ho_date.success'),
          messageType: SUCCESS,
        },
      }),
    )
    yield put(loadingData(false))
  } catch (e) {
    console.error(e)
    yield put(loadingData(false))
    yield put(
      showNotification({
        payload: {
          message: translate('job.replace_ho_date.error'),
          messageType: WARNING,
        },
      }),
    )
  }
}

const mergeMatchingProsAndReliablePros = (matchingPros, reliableList) =>
  reliableList.map(proData => {
    const matchingProResponses = matchingPros.filter(
      matchingPro => matchingPro.pro['@id'] === proData['@id'],
    )

    if (matchingProResponses.length === 0) {
      return {
        pro: proData,
        proResponse: { value: null },
        status: PRO_NOT_NOTIFIED_STATUS,
        notificationsCountForJob: 0,
        timeslot: null,
      }
    }

    return matchingProResponses[0]
  })

export function* handleReadReliableProListRequest(searchParams) {
  try {
    const matchingPros = yield select(matchingProsSelector)
    const productCode = yield select(productCodesSelector)

    try {
      const response = yield engineClient.get('pros', {
        params: {
          reliable: true,
          availableForPackage: true,
          productCode,
          page: searchParams.page,
          perPage: searchParams.rowsPerPage,
        },
      })

      const reliablePros = mergeMatchingProsAndReliablePros(
        matchingPros,
        response.data['hydra:member'],
      )

      yield put(
        reliableProList.success(
          reliablePros,
          response.data['hydra:totalItems'],
        ),
      )
    } catch (e) {
      yield put(reliableProList.failure(e))

      throw e
    }
  } catch (e) {
    console.error(e)
    yield put(
      showNotification({
        payload: {
          message: translate('job.manual_matching.get_reliable_pro.error'),
          messageType: WARNING,
        },
      }),
    )
  }
}

function* handleGetReceiptJob({ jobIri }) {
  const options = { triggerModalLoader: true }
  const promise = call(
    engineClient.get,
    `${formatIriForTransition(jobIri)}/receipt`,
  )
  yield* engineAPI(getReceiptJob, promise, options, 'receipt')
}

function* handleGetCerfaJob({ jobIri, cerfaType }) {
  const options = { triggerModalLoader: true }
  const promise = call(
    engineClient.get,
    `${formatIriForTransition(jobIri)}/cerfa_${cerfaType}`,
  )
  yield* engineAPI(getCerfaJob, promise, options, 'cerfa')
}

function* handleGetReceiptSignature({ jobIri }) {
  const options = { triggerModalLoader: true }
  const promise = call(
    engineClient.get,
    `${formatIriForTransition(jobIri)}/receipt/signature`,
  )
  yield* engineAPI(getReceiptSignature, promise, options, 'receipt_signature')
}

function* handleGetCerfaSignature({ jobIri }) {
  const options = { triggerModalLoader: true }

  const promise = call(
    engineClient.get,
    `${formatIriForTransition(jobIri)}/cerfa/signature`,
  )

  yield* engineAPI(getCerfaSignature, promise, options, 'cerfa_signature')
}

function* handleUnblockReceipt({ payload }) {
  const { jobId, reserves } = payload

  yield put(
    updateJob.request({
      jobData: {
        '@id': jobId,
        receiptSignatureStatus:
          reserves === translate('resources.receipt.reserveText')
            ? RECEIPT_SIGNATURE_STATE_MANUAL
            : RECEIPT_SIGNATURE_STATE_FINISHED,
      },
      documentName: translate('resources.jobs.fields.receipt'),
    }),
  )
}

function* handleGetMatchings({ jobIri }) {
  const options = { composeTriggerModalLoader: true }
  const promise = call(
    engineClient.get,
    `${formatIriForTransition(jobIri)}/matchings`,
  )

  yield* engineAPI(getMatchings, promise, options, 'matchings')
}

export function* handleGenerateSupportingDocuments({ jobIri }) {
  yield* handleRequest({
    requestActions: generateSupportingDocuments,
    promise: call(n8nClient.post, '/webhook/generate-supporting-documents', {
      jobId: getJobIdFromIri(jobIri),
    }),
    actionParams: { triggerModalLoader: true },
  })
}

function* handleGenerateSupportingDocumentsSuccess() {
  yield put(
    showNotification({
      payload: {
        messageType: SUCCESS,
        message: translate(`job.generate_supporting_documents.success`),
      },
    }),
  )
}

function* handleGenerateSupportingDocumentsFailure() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate(`job.generate_supporting_documents.failure`),
      },
    }),
  )
}

export function* handleGenerateDisputeDocuments({ jobIri }) {
  yield* handleRequest({
    requestActions: generateDisputeDocuments,
    promise: call(n8nClient.post, '/webhook/generate-dispute-documents', {
      jobId: getJobIdFromIri(jobIri),
    }),
    actionParams: { triggerModalLoader: true },
  })
}

function* handleGenerateDisputeDocumentsSuccess() {
  yield put(
    showNotification({
      payload: {
        messageType: SUCCESS,
        message: translate(`job.generate_dispute_documents.success`),
      },
    }),
  )
}

function* handleGenerateDisputeDocumentsFailure() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate(`job.generate_dispute_documents.failure`),
      },
    }),
  )
}

function* handleGenerateReinterventionAfterSale({ incidentId, useInitialPro }) {
  try {
    yield* handleRequest({
      requestActions: generateReinterventionAfterSale,
      promise: call(
        engineClient.post,
        `/incidents/${incidentId}/create_after_sale_job`,
        {
          useInitialPro,
        },
      ),
      actionParams: {
        triggerModalLoader: true,
        useInitialPro,
      },
    })
  } catch (e) {
    const jobIri = yield select(jobIdSelector)
    const jobId = getJobIdFromIri(jobIri)

    if (e?.response?.data?.error?.code === '4221') {
      yield put(refreshJob.request({ jobId: `/jobs/${jobId}` }))
      yield put(
        showNotification({
          payload: {
            messageType: ERROR,
            message: translate('app.generic_error'),
          },
        }),
      )
    }
  }
}

function* handleGenerateReinterventionAfterSaleSuccess({
  actionParams,
  payload,
}) {
  const worksite = yield select(currentWorksiteSelector)

  yield put(getSingleWorksite.request({ worksiteId: worksite.worksiteId }))
  if (actionParams.useInitialPro === false) {
    yield put(
      push(
        `${JOB_PATH_MANUAL_MATCHING_PREFIX}/${encodeURIComponent(
          '/engine/jobs/',
        )}${payload.job_id}`,
      ),
    )
  }
}

function* postClientDataSaga({
  data: { address, ...shippingAddress },
  orderId,
  initialValues,
}) {
  const shippingAddressData = removeUnchangedAttribute(
    initialValues,
    removeEmptyAttribute(shippingAddress),
  )

  if (Object.keys(shippingAddressData).length === 0) {
    yield put(
      showNotification({
        payload: {
          messageType: ERROR,
          message: translate(`job.job_update.req_abort`),
        },
      }),
    )
    return
  }
  yield* handleRequest({
    requestActions: postClientData,
    promise: call(syliusClient.put, `/order-api/orders/${orderId}`, {
      shippingAddress: {
        ...shippingAddressData,
      },
    }),
    actionParams: {
      triggerModalLoader: true,
    },
  })
}

function* postClientDataSagaSuccess() {
  yield put(
    showNotification({
      payload: {
        messageType: SUCCESS,
        message: translate(`job.job_update.req_success`),
      },
    }),
  )
}

function* postClientDataSagaFailure() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate(`job.job_update.req_failure`),
      },
    }),
  )
}

function* handleGetJobDiagnostic(data) {
  const jobIri = data.jobIri.slice(7)
  try {
    yield handleRequest({
      requestActions: getJobDiagnostic,
      promise: call(engineClient.get, `${jobIri}/diagnostic`),
    })
  } catch (e) {
    yield put(
      showNotification({
        payload: {
          message: translate('job.job_diagnostic.req_failure'),
          messageType: WARNING,
        },
      }),
    )
  }
}

function* handleUpdateDiagReq({
  data,
  jobIri,
  diagInfos: { comment, newJobRequired, additionalFeesAmount },
}) {
  try {
    yield handleRequest({
      requestActions: updateDiag,
      promise: call(
        engineClient.put,
        `${formatIriForTransition(jobIri)}/diagnostic`,
        {
          comment,
          newJobRequired,
          additionalFeesAmount,
          ...data,
        },
      ),
      actionParams: {
        triggerModalLoader: true,
        jobIri,
      },
    })
  } catch {
    yield put(
      showNotification({
        payload: {
          message: translate('app.generic_error'),
          messageType: ERROR,
        },
      }),
    )
  }
}

function* handleUpdateDiagSuccess({ actionParams: { jobIri } }) {
  yield put(getJobDiagnostic.request({ jobIri }))
}

export default function*() {
  yield all([
    takeLatest(JOB_ADD_COMMENT_REQ.REQUEST, handleAddJobCommentRequest),
    takeLatest(GET_JOBS_LIST.REQUEST, handleGetJobList),
    takeLatest(EXPORT_JOBS_LIST.REQUEST, handleExportJobList),
    takeLatest(RESET_PROS_SEARCH.REQUEST, handleResetProsSearch),
    takeLatest(RESET_JOBS_SEARCH, handleResetJobsSearch),
    takeLatest(GET_MATCHING_PROS_LIST.REQUEST, handleGetMatchingProsAlgolia),
    takeLatest(
      GET_MATCHING_PROS_LIST_RANKING.REQUEST,
      handleGetMatchingProsAlgoliaRanking,
    ),
    takeLatest(
      [
        GET_SINGLE_JOB.REQUEST,
        JOB_DECLINE_TRANSITION_REQ.SUCCESS,
        TO_PENDING_PAYMENT_TRANSITION_REQ.SUCCESS,
        TO_PENDING_CANCELATION_TRANSITION_REQ.SUCCESS,
        TO_CANCEL_TRANSITION_REQ.SUCCESS,
        TO_DONE_TRANSITION_REQ.SUCCESS,
        INVOICE_PAY_TRANSITION_REQ.SUCCESS,
      ],
      handleGetSingleJob,
    ),
    takeLatest([REFRESH_JOB.REQUEST], handleRefreshJob),
    takeLatest([GET_MATCHING_PROS.REQUEST], handleGetMatchingPros),
    takeLatest(
      [GET_COUNT_JOBS_BY_STATUSES.REQUEST],
      handleGetCountJobsByStatuses,
    ),
    takeLatest(
      GET_JOB_EVENTS_FOR_JOB_REQ.REQUEST,
      handleGetJobEventsForJobRequest,
    ),
    takeLatest(UPDATE_JOB_REQ.REQUEST, handleUpdateJobReq),
    takeLatest(UPDATE_JOB_HO_DATE_REQ.REQUEST, handleUpdateJobHoDateReq),
    takeLatest(
      [
        RELIABLE_PRO_LIST.REQUEST,
        JOB_DECLINE_TRANSITION_REQ.REQUEST,
        JOB_RESCHEDULING_TRANSITION_REQ.REQUEST,
      ],
      handleReadReliableProListRequest,
    ),
    takeLatest(GET_RECEIPT_JOB.REQUEST, handleGetReceiptJob),
    takeLatest(GET_CERFA_JOB.REQUEST, handleGetCerfaJob),
    takeLatest(GET_RECEIPT_SIGNATURE.REQUEST, handleGetReceiptSignature),
    takeLatest(GET_CERFA_SIGNATURE.REQUEST, handleGetCerfaSignature),
    takeLatest([POST_INCIDENT_REQ.SUCCESS], handleRefreshJobEvents),
    takeLatest(UNBLOCK_JOB_RECEIPT, handleUnblockReceipt),
    takeLatest(GET_MATCHINGS.REQUEST, handleGetMatchings),
    takeLatest(
      [GENERATE_SUPPORTING_DOCUMENTS_REQ.REQUEST],
      handleGenerateSupportingDocuments,
    ),
    takeLatest(
      [GENERATE_SUPPORTING_DOCUMENTS_REQ.SUCCESS],
      handleGenerateSupportingDocumentsSuccess,
    ),
    takeLatest(
      [GENERATE_SUPPORTING_DOCUMENTS_REQ.FAILURE],
      handleGenerateSupportingDocumentsFailure,
    ),

    takeLatest(
      [GENERATE_DISPUTE_DOCUMENTS_REQ.REQUEST],
      handleGenerateDisputeDocuments,
    ),
    takeLatest(
      [GENERATE_DISPUTE_DOCUMENTS_REQ.SUCCESS],
      handleGenerateDisputeDocumentsSuccess,
    ),
    takeLatest(
      [GENERATE_DISPUTE_DOCUMENTS_REQ.FAILURE],
      handleGenerateDisputeDocumentsFailure,
    ),
    takeLatest(POST_CLIENT_DATA_REQ.REQUEST, postClientDataSaga),
    takeLatest(POST_CLIENT_DATA_REQ.SUCCESS, postClientDataSagaSuccess),
    takeLatest(POST_CLIENT_DATA_REQ.FAILURE, postClientDataSagaFailure),
    takeLatest(
      GENERATE_REINTERVENTION_AFTERSALE_REQ.REQUEST,
      handleGenerateReinterventionAfterSale,
    ),
    takeLatest(
      GENERATE_REINTERVENTION_AFTERSALE_REQ.SUCCESS,
      handleGenerateReinterventionAfterSaleSuccess,
    ),
    takeLatest(GET_JOB_DIAGNOSTIC.REQUEST, handleGetJobDiagnostic),
    takeLatest(UPDATE_DIAG_REQ.REQUEST, handleUpdateDiagReq),
    takeLatest(UPDATE_DIAG_REQ.SUCCESS, handleUpdateDiagSuccess),
  ])
}
