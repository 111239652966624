import translate from 'providers/i18n/translateService'

export const LOGIN_FORM = 'LOGIN_FORM'
export const FILTER_FIRMS_LIST_FORM = 'FILTER_FIRMS_LIST_FORM'

export const EDIT_FIRM_DETAILS_PRO_FORM = 'EDIT_FIRM_DETAILS_PRO_FORM'
export const EDIT_FIRM_DETAILS_PACKAGES_FORM = 'EDIT_FIRM_DETAILS_PACKAGES_FORM'
export const EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM =
  'EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM'
export const EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM =
  'EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM'
export const EDIT_FIRM_INTERVENTION_ZONE_FORM =
  'EDIT_FIRM_INTERVENTION_ZONE_FORM'
export const EDIT_CLIENT_INTERVENTION_DATA_FORM =
  'EDIT_CLIENT_INTERVENTION_DATA_FORM'
export const EDIT_DIAG_FORM = 'EDIT_DIAG_FORM'
export const ADD_FIRM_COMMENT_FORM = 'ADD_FIRM_COMMENT_FORM'
export const FILTER_JOBS_LIST_FORM = 'FILTER_JOBS_LIST_FORM'
export const FILTER_INVOICES_LIST_FORM = 'FILTER_INVOICES_LIST_FORM'
export const FILTER_SALE_INVOICES_LIST_FORM = 'FILTER_SALE_INVOICES_LIST_FORM'
export const FILTER_SALE_ALL_INVOICES_LIST_FORM =
  'FILTER_SALE_ALL_INVOICES_LIST_FORM'
export const EDIT_INVOICE_FORM = 'EDIT_INVOICE_FORM'
export const RESCHEDULING_JOB_FORM = 'RESCHEDULING_JOB_FORM'
export const SEARCH_PROS_MANUAL_MATCHING = 'SEARCH_PROS_MANUAL_MATCHING'
export const DOCUMENT_FORM = 'DocumentForm'
export const NEW_TIME_SLOT_DATE_FORM = 'NEW_TIME_SLOT_DATE_FORM'
export const SET_INCIDENT_RESPONSIBLE_FORM = 'SET_INCIDENT_RESPONSIBLE'
export const POST_INCIDENT_FORM = 'POST_INCIDENT_FORM'
export const LIST_INCIDENT_STATUSES_FORM = 'LIST_INCIDENT_STATUSES_FORM'
export const GENERATE_REINTERVENTION_AFTERSALE_FORM =
  'GENERATE_REINTERVENTION_AFTERSALE_FORM'
export const GENERATE_ADDITIONNAL_INVOICE_FORM =
  'GENERATE_ADDITIONNAL_INVOICE_FORM'
export const UPDATE_COLLABORATOR_FORM = 'UPDATE_COLLABORATOR_FORM'
export const FIRM_CERTIFICATE_FORM = {
  NAME: 'FIRM_CERTIFICATE_FORM',
  FIELDS: {
    TYPE: 'typeCertificat',
    CATEGORIES: 'categoryCertificat',
    EXPIRATION_DATE: 'expirationDate',
    ISSUE_DATE: 'issueDate',
    REFERENCE: 'reference',
  },
}
export const UPDATE_USER_SUSPENSION_REASON_FORM =
  'UPDATE_USER_SUSPENSION_REASON_FORM'

export const YES = 'true'
export const NO = 'false'
export const YES_LABEL = 'YES'
export const NO_LABEL = 'NO'
export const YES_FILTER = {
  code: YES,
  name: translate(`system.filter.${YES_LABEL}`),
}
export const DEFAULT_YES_NO_FILTER = [
  {
    code: YES,
    name: translate(`system.filter.${YES_LABEL}`),
  },
  {
    code: NO,
    name: translate(`system.filter.${NO_LABEL}`),
  },
]
