import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import {
  withStyles,
  Grid,
  Card,
  CardContent,
  Typography,
  TextareaAutosize,
  Button,
  Link,
  InputAdornment,
} from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import { Field } from 'redux-form'

import RenderTextField from 'components/shared/Form/RenderTextField'
import FormDialogContainer from 'components/shared/FormDialog/FormDialogContainer'
import Label from 'components/shared/Label/Label'
import { FIELD_DIAG_AMOUNT } from 'constants/Jobs'
import { hasPerm, PERM_JOB_EDIT_DIAGNOSTIC } from 'constants/permissions'

import styles from './JobDiagnosticStyles'

const JobDiagnostic = ({
  diagInfos: { newJobRequired, additionalFeesAmount, comment, createdAt },
  classes,
  translate,
  token,
  submitFormDispatch,
  formErrors,
  formValues,
  form,
}) => {
  const [confirmDialogOpened, setDialogOpened] = useState(false)
  const userHasPermission = hasPerm(token, PERM_JOB_EDIT_DIAGNOSTIC)
  const [formHasErrors, setFormHasErrors] = useState(false)

  useEffect(() => {
    setFormHasErrors(false)
    Object.keys(formErrors).map(error => {
      if (formErrors[error] !== undefined) {
        setFormHasErrors(true)
      }

      return formHasErrors
    })
  }, [formErrors, formHasErrors])

  const handleEditFormClose = () => {
    setDialogOpened(!confirmDialogOpened)
  }
  const handleResponse = () => {
    if (formValues?.[FIELD_DIAG_AMOUNT]) {
      submitFormDispatch()
      handleEditFormClose()
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography className={classes.typography}>
              Date de réalisation :{' '}
              <span className={classes.bold}>
                {/* Format the date to display only the date without the time (e.g. 24-09-2024) */}
                {new Date(createdAt).toLocaleDateString('fr-FR')}
              </span>
            </Typography>
            <Typography className={classes.typography}>
              Résultat :{' '}
              <span className={classes.bold}>
                {newJobRequired
                  ? translate('resources.jobs.diagnostic.newJobRequired')
                  : translate('resources.jobs.diagnostic.noNewJobRequired')}
              </span>
            </Typography>
            <Typography className={classes.typography}>
              Frais additionnels engagés :{' '}
              <span className={classes.bold}>
                {additionalFeesAmount > 0
                  ? `${additionalFeesAmount} €HT`
                  : translate('resources.jobs.diagnostic.noAdditionalFees')}
              </span>
            </Typography>
            {newJobRequired && (
              <>
                <Button
                  name="generateDisputeDocuments"
                  className={classes.buttonSecondary}
                  variant="contained"
                  component={Link}
                  onClick={() => setDialogOpened(true)}
                  disabled={!userHasPermission}
                >
                  <EditOutlined />
                  {translate('job.details.client.form.pop_in.button')}
                </Button>
                {confirmDialogOpened && (
                  <FormDialogContainer
                    title="diagnostic.edit.form.title"
                    onSubmit={handleResponse}
                    handleClose={handleEditFormClose}
                    form={form}
                    translate={translate}
                  >
                    <Grid className={classes.marginBottom}>
                      <Label label={translate('diagnostic.edit.form.label')} />
                      <Field
                        id={FIELD_DIAG_AMOUNT}
                        name={FIELD_DIAG_AMOUNT}
                        component={RenderTextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">€</InputAdornment>
                          ),
                        }}
                        fullWidth
                        value=""
                        type="number"
                      />
                    </Grid>
                  </FormDialogContainer>
                )}
              </>
            )}
            <TextareaAutosize
              className={classes.textArea}
              readOnly
              value={comment}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

JobDiagnostic.propTypes = {
  classes: PropTypes.shape({
    typography: PropTypes.string.isRequired,
    textArea: PropTypes.string.isRequired,
    bold: PropTypes.string.isRequired,
    buttonSecondary: PropTypes.string.isRequired,
    simpleInputText: PropTypes.string.isRequired,
    formControl: PropTypes.string.isRequired,
    marginBottom: PropTypes.string.isRequired,
  }).isRequired,
  diagInfos: PropTypes.shape({
    newJobRequired: PropTypes.bool.isRequired,
    additionalFeesAmount: PropTypes.number.isRequired,
    comment: PropTypes.string.isRequired,
    createdAt: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  token: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  }).isRequired,
  submitFormDispatch: PropTypes.func.isRequired,
  formErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  formValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  form: PropTypes.string.isRequired,
}

export default compose(i18n, withStyles(styles))(JobDiagnostic)
