import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import {
  setBarTitle,
  setShowJobStatus,
} from 'store/Application/ApplicationActions'
import {
  getSingleJob,
  getMatchings as getMatchingsAction,
} from 'store/jobs/jobActions'
import { getSingleWorksite } from 'store/worksites/worksiteActions'
import {
  statusSelector,
  substatusSelector,
  orderNumberSelector,
  jobDetailsTabSelector,
  requiredCertificateSelector,
  jobPicturesSelector,
  worksiteIdSelector,
  partnerIdSelector,
  selectOpenedIncidentsCount,
  currentJobSelector,
} from 'store/jobs/jobSelectors'
import {
  AFTER_SALE_ALL_TYPES,
  CERFA,
  MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE,
  MANUAL_RESOLUTION_AFTER_SALE_TYPE,
} from 'constants/Jobs'
import { getJobIdFromIri } from 'helpers/utils/job/job'
import { useTaskCaller } from 'helpers/useTaskCaller'
import { PARTNER_LM } from 'constants/partners'
import JobDetails from './JobDetails'

const JobDetailsContainer = ({
  status,
  substatus,
  orderNumber,
  getJob,
  worksiteId,
  partnerId,
  getWorksite,
  setTitle,
  setJobStatusVisible,
  match: {
    params: { jobId },
  },
  translate,
  showJobDetailsTab,
  isCerfaPackage,
  jobPictures,
  openedIncidentsCount,
  getMatchings,
  type,
  hasDiagnostic,
}) => {
  const decodedJobId = decodeURIComponent(jobId)

  const fetchJob = useCallback(() => {
    getJob({ jobId: decodedJobId })
  }, [getJob, decodedJobId])

  const [ready] = useTaskCaller(fetchJob)

  useEffect(() => {
    const isAfterSale = AFTER_SALE_ALL_TYPES.includes(type)
    const afterSaleTypeMap = {
      [MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE]: '.manual_diagnostic',
      [MANUAL_RESOLUTION_AFTER_SALE_TYPE]: '.manual_resolution',
    }
    const afterSafeType = isAfterSale ? afterSaleTypeMap[type] || '' : ''
    if (orderNumber) {
      if (partnerId === PARTNER_LM.id) {
        const translateKey = isAfterSale
          ? `resources.after_sale_jobs.navbar.title${afterSafeType}.detail2`
          : 'resources.jobs.navbar.title.detail2'
        setTitle(
          translate(translateKey, {
            orderNumber,
            jobId: getJobIdFromIri(decodedJobId),
          }),
        )
      } else {
        const translateKey = isAfterSale
          ? `resources.after_sale_jobs.navbar.title${afterSafeType}.detail_jobid`
          : 'resources.jobs.navbar.title.detail_jobid'
        setTitle(
          translate(translateKey, {
            jobId: getJobIdFromIri(decodedJobId),
          }),
        )
      }

      setJobStatusVisible(true)
    }

    return () => {
      setJobStatusVisible(false)
    }
  }, [
    orderNumber,
    setTitle,
    setJobStatusVisible,
    translate,
    decodedJobId,
    partnerId,
    type,
    hasDiagnostic,
  ])

  useEffect(() => {
    getMatchings(jobId)
  }, [getMatchings, jobId])

  useEffect(() => {
    if (worksiteId) {
      getWorksite({ worksiteId })
    }
  }, [worksiteId, getWorksite])

  return ready && status ? (
    <JobDetails
      status={status}
      substatus={substatus}
      jobId={decodedJobId}
      showJobDetailsTab={showJobDetailsTab}
      isCerfaPackage={isCerfaPackage}
      jobPictures={jobPictures}
      openedIncidentsCount={openedIncidentsCount}
      partnerId={partnerId}
      jobType={type}
      hasDiagnostic={hasDiagnostic}
    />
  ) : null
}

JobDetailsContainer.propTypes = {
  status: PropTypes.string,
  substatus: PropTypes.string,
  orderNumber: PropTypes.string,
  worksiteId: PropTypes.string,
  partnerId: PropTypes.number,
  translate: PropTypes.func.isRequired,
  getJob: PropTypes.func.isRequired,
  getWorksite: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setJobStatusVisible: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ jobId: PropTypes.string.isRequired }),
  }).isRequired,
  showJobDetailsTab: PropTypes.arrayOf(PropTypes.string).isRequired,
  isCerfaPackage: PropTypes.bool.isRequired,
  jobPictures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      path: PropTypes.string,
      comment: PropTypes.string,
    }),
  ),
  openedIncidentsCount: PropTypes.number.isRequired,
  getMatchings: PropTypes.func.isRequired,
  type: PropTypes.string,
  hasDiagnostic: PropTypes.bool,
}

JobDetailsContainer.defaultProps = {
  status: undefined,
  substatus: undefined,
  orderNumber: undefined,
  worksiteId: undefined,
  partnerId: undefined,
  jobPictures: [],
  type: undefined,
  hasDiagnostic: false,
}

const mapStateToProps = state => ({
  status: statusSelector(state),
  substatus: substatusSelector(state),
  orderNumber: orderNumberSelector(state),
  worksiteId: worksiteIdSelector(state),
  partnerId: partnerIdSelector(state),
  showJobDetailsTab: jobDetailsTabSelector(state),
  isCerfaPackage: CERFA === requiredCertificateSelector(state),
  jobPictures: jobPicturesSelector(state),
  openedIncidentsCount: selectOpenedIncidentsCount(state),
  type: currentJobSelector(state).type,
  hasDiagnostic: !!currentJobSelector(state).diagnostic,
})

const mapDispatchToProps = dispatch => ({
  getJob: searchParams => dispatch(getSingleJob.request(searchParams)),
  getWorksite: worksiteId => dispatch(getSingleWorksite.request(worksiteId)),
  setTitle: barTitle => dispatch(setBarTitle({ barTitle })),
  setJobStatusVisible: showJobDetailsStatus =>
    dispatch(setShowJobStatus({ showJobDetailsStatus })),
  getMatchings: id =>
    dispatch(getMatchingsAction.request({ jobIri: decodeURIComponent(id) })),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(JobDetailsContainer)
